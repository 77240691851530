.ant-card.primary .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
    color: #1890ff;
    font-weight: bold;
}

/*
   去除颜色选择器弹出层的内边距
*/
.color-picker-popover .ant-popover-inner-content {
    padding: 0;
}

th.write-back {
    background-color: #D0E6FF !important;
}
