@media print {
    .no-print{
        display:none;
    }
    body {
        /*zoom: 0.66*/
        width: 1075px;
        margin: auto;
    }
    #root, .ant-spin-nested-loading, .ant-spin-container{
        width: 1075px;
        margin: auto;
    }
}

body {
    height: auto;
    /*color: #333;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, SimSun, sans-serif;
    font-weight: 400;
    background: #f9f9f9;*/
}

/*重写滚动条样式
body::-webkit-scrollbar {
width: 10px;
height: 10px;
}

body::-webkit-scrollbar-button {
background-color: #eee;
}

body::-webkit-scrollbar-track {
background: #eee;
}

body::-webkit-scrollbar-thumb {
background: #bbb;
}

body::-webkit-scrollbar-corner {
background: #bbb;
}*/

/*#root, ant-spin-nested-loading, .ant-spin-container {
    width: 1075px;
    margin: auto;
}*/

#printContent{
    color: #333;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, SimSun, sans-serif;
    font-weight: 400;
}

#printContent td {
    word-break: break-all
}

#printContent th {
    word-break: break-all
}

#printContent .l {
    float: left
}

#printContent .r {
    float: right
}

.order-edit-content {
    width: 1075px;
    margin: auto;
    padding-bottom: 20px;
}

table.print-table {
    border-collapse: collapse;
    border: 0px
}

table.print-table th, table.print-table td {
    border: 0px
}

table.table-edit {
    border-collapse: collapse;
    border: 0px;
    width: 100%
}

table.table-edit th {
    padding: 5px;
    border: 1px solid #000;
    font-size: 14px;
    text-align: center;
    color: #333;
    font-weight: normal;
    background-color: #fff;
    position: relative;
}

table.table-edit th.align-right {
    text-align: right
}

table.table-edit th.align-center {
    text-align: center
}

table.table-edit td {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    position: relative;
    padding: 5px
}

table.table-edit td.align-right {
    text-align: right
}

table.table-edit td.align-center {
    text-align: center
}

table.table-edit td.bg {
    background: #4fd300;
    color: #fff
}

table.table-edit th.bg {
    background: #ccc
}

table.table-edit td a {
    text-decoration: underline;
    color: #06F
}

table.table-edit th, table.table-edit td {
    font-size: 14px;
    background: none
}